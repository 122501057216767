/** THIS IS COMMON CODE. PLEASE DO NOT CHANGE WITHOUT DISCUSSION WITH THE TEAM **/
import axios from 'axios'
import store from '@/store'
import router from '@/router'
import * as mainService from '@/components/common/mixins/index.js'
import {
  PORTAL,
  V1_API_BASE_URL,
  V2_API_BASE_URL,
  API_DEFAULT_SERVICE,
  CAPTABLE_SOURCES_API,
  CAPTABLE_SOURCES_TYPES,
} from '@/constants'

const axiosInstance = axios.create({
  baseURL: V2_API_BASE_URL,
  timeout: 0,
  params: {}, // do not remove this, its added to add params later in the config
  headers: {
    'Content-Type': 'application/json',
  },
})

// Add a request interceptor
axiosInstance.interceptors.request.use(
  (conf, request) => {
    const token = store.getters['auth/trellisAccessToken']
    const source = store.getters['auth/source']
    if (token && source === CAPTABLE_SOURCES_TYPES.V2) {
      conf.headers.common.authorization = `Bearer ${token}`
    } else if (token && source === CAPTABLE_SOURCES_TYPES.DL) {
      conf.headers.common.authorization = `${token}`
    } else if (source === CAPTABLE_SOURCES_TYPES.ISSUER) {
      conf.headers.common.authorization = `${token}`
    }
    return conf
  },
  (error) => Promise.reject(error),
)

axiosInstance.interceptors.response.use(null, function (error) {
  const portal = mainService.$getPortal()
  if ((error.response?.status === 403 || error.response?.status === 401) && portal === PORTAL.CAPTABLE) {
    router.push({ name: 'JwtExpired' })
  }
  return Promise.reject(error)
})

const defaultOption = {
  base: API_DEFAULT_SERVICE,
}

/**
  * options.base - base.trellisplatform.com
  */
const getUrl = (resource, options) => {
  let base = ''
  const fidId = store.getters['auth/currentFidId']
  const issuerId = store.getters['auth/currentOrganization']?.id
  if (!options.base) {
    base = API_DEFAULT_SERVICE
  } else if (options.base === CAPTABLE_SOURCES_API.DL) {
    base = `${V1_API_BASE_URL}/fid/${fidId}`
  } else if (options.base === CAPTABLE_SOURCES_API.ISSUER) {
    base = `${V1_API_BASE_URL}/issuer/${issuerId}`
  } else { // can be common
    base = `${V2_API_BASE_URL}/${options.base}/v1`
  }

  return `${base}/${resource}`
}

export const api = {
  axiosInstance,

  /**
   * get - get request to the api
   *
   * @param {string} resource
   * @param {any} params
   * @returns {Promise<AxiosResponse>}
   * @memberof api
   */
  async get (resource, params, options = {}) {
    options = Object.assign({}, defaultOption, options)
    return axiosInstance.get(getUrl(resource, options), params)
  },

  /**
   * post - post request to the api
   *
   * @param {string} resource
   * @param {any} body
   * @returns {Promise<AxiosResponse>}
   * @memberof api
   */
  async post (resource, body, options = {}) {
    options = Object.assign({}, defaultOption, options)
    return axiosInstance.post(getUrl(resource, options), body)
  },

  /**
   * patch - patch request to the api
   *
   * @param {string} resource
   * @param {any} body
   * @returns {Promise<AxiosResponse>}
   * @memberof api
   */
  async patch (resource, body, options = {}) {
    options = Object.assign({}, defaultOption, options)
    return axiosInstance.patch(getUrl(resource, options), body)
  },

  /**
   * put - put request to the api
   *
   * @param {string} resource
   * @param {any} body
   * @returns {Promise<AxiosResponse>}
   * @memberof api
   */
  async put (resource, body, options = {}) {
    options = Object.assign({}, defaultOption, options)
    return axiosInstance.put(getUrl(resource, options), body)
  },

  /**
   * delete - delete request to the api
   *
   * @param {string} resource
   * @returns {Promise<AxiosResponse>}
   * @memberof api
   */
  async delete (resource, options = {}) {
    options = Object.assign({}, defaultOption, options)
    return axiosInstance.delete(getUrl(resource, options))
  },

  /**
   * upload - post multipart from
   *
   * @param {string} resource
   * @param {any} body
   * @returns {Promise<AxiosResponse>}
   * @memberof api
   */
  async upload (resource, body, options, progressCallback, method = 'post') {
    options = Object.assign({}, defaultOption, options)
    return axiosInstance[method](getUrl(resource, options), body, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent) => {
        const uploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100), 10)

        progressCallback && progressCallback(uploadPercentage)
      },
    })
  },
}
