<template>
  <common-trs-page-container>
    <common-app-page-header-v2
      title="Dashboard"
      icon="mdi mdi-view-dashboard-outline"
      :padding="0"
    />
    <v-progress-linear
      v-if="loading"
      color="primary"
      indeterminate
    />
    <v-list
      v-if="captableList && captableList.length > 0"
    >
      <v-subheader>Available Cap Tables</v-subheader>
      <v-list-item-group
        color="primary"
      >
        <v-list-item
          v-for="(captableData, key) of captableList"
          :key="key"
          @click="navigateToDetailsPage(captableData)"
        >
          <span v-if="captableData && captableData.name">{{ captableData.name }}</span>
          <span v-else-if="captableData && captableData.orgDetails && captableData.orgDetails.name">{{ captableData.orgDetails.name }}</span>
          <span v-else>Captable {{ key+1 }}</span>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <div v-if="!loading && (!captableList || captableList.length === 0)">
      No data found.
    </div>
  </common-trs-page-container>
</template>

<script>
  import * as captableHolderService from '@/services/captable-holder-service.js'
  import * as captableService from '@/components/common/captable/services/captable'

  export default {
    name: 'HoldersLandingPage',

    data: () => ({
      captableList: [],
      loading: false,
      isDataUpdated: false,
    }),

    computed: {
      holderCaptableList () {
        return this.$store.getters['auth/holderCaptableList']
      },
      source () {
        return this.$store.getters['auth/source']
      },
      roles () {
        return this.$store.getters['auth/roles']
      },
      user () {
        return this.$store.getters['auth/user']
      },
    },

    watch: {
      source: {
        handler (val) {
          if (val) {
            this.updateCaptableList()
          }
        },
        immediate: true,
      },
    },

    async mounted () {
      this.$store.dispatch('auth/setIsDirectLogin', false)
    },

    methods: {
      async updateCaptableList () {
        this.loading = true
        if (this.source === this.CONSTS.CAPTABLE_SOURCES_TYPES.V2 && !this.isDataUpdated) {
          await this.getCaptaleList(this.holderCaptableList)
          this.isDataUpdated = true
        } else if ((!this.holderCaptableList || this.holderCaptableList?.length === 0) && this.source === this.CONSTS.CAPTABLE_SOURCES_TYPES.DL) {
          await this.getHolderCaptableList()
        } else {
          this.captableList = this.holderCaptableList
        }
        this.loading = false
      },
      async getHolderCaptableList () {
        try {
          const resp = await captableHolderService.getCaptableList()
          if (resp) {
            const cyndxToken = { external_token: resp.external_token }
            this.$store.dispatch('auth/setAuthInfo', cyndxToken)
            this.getCaptaleList(resp?.captable_list)
          }
        } catch (e) {
          this.loading = false
        }
      },
      async getCaptaleList (list) {
        await Promise.all(list?.map(item => this.updateOrgData(item)))
        await Promise.all(list?.map(item => this.getCaptableHolderDetails(item)))
        this.captableList = list
        this.$store.dispatch('auth/setHolderCaptableList', this.captableList)
        this.loading = false
      },
      async getCaptableHolderDetails (item) {
        item.holderDetails = []
        if (item?.holder_id) {
          item.holder_id_list = [item?.holder_id]
        }
        return await Promise.all(item?.holder_id_list?.map(holderId => this.updateHolderData(item, holderId)))
      },

      async updateHolderData (item, holderId) {
        try {
          if (holderId) {
            const resp = await captableService.getProfileById(item.captable_id, holderId)
            if (resp && resp.data) {
              item.holderDetails.push({
                id: resp.data.id,
                email: resp.data.email,
                first_name: resp.data.name,
              })
            }
          }
          return item
        } catch (e) {
          this.loading = false
        }
      },
      async updateOrgData (item) {
        try {
          const resp = await captableService.getOrganizationById(item.captable_id)
          if (resp && resp.data) {
            item.orgDetails = {
              id: resp.data.id,
              name: resp.data.name,
            }
          }
          return item
        } catch (e) {
          this.loading = false
        }
      },

      navigateToDetailsPage (captableData) {
        this.$store.dispatch('auth/setCaptableId', captableData.captable_id)
        this.$store.dispatch('auth/setCurrentOrganization', captableData)
        this.$store.dispatch('auth/setHolder', { ...this.user, id: captableData?.holder_id_list })
        this.$store.dispatch('auth/setSelectedCaptableHolder', captableData?.holderDetails)
        if (this.roles.includes(this.CONSTS.USER_ROLES.HOLDER.toLocaleLowerCase())) {
          this.$router.push({ name: 'Holder.CaptableSummary' })
        } else {
          this.$router.push({ name: 'Captable' })
        }
      },
    },
  }
</script>
